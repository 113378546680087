
/*icon-footer*/
.icon-foote {
  background-color:var(--secondary-color);
  /* width: 100%; */
  /* padding-top: 90px ; */
  z-index: 9;
}

.icon-foote .mob{
  display: none;
}

.icon-foote .container .logo,
.icon-foote .container .mail,
.icon-foote .container .subscribe {
  width: 40%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon-foote .container .logo .icon-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon-foote .container .logo .icon-container .icon-box {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  transition: all .4s;
  color: var(--color-white);
}


.icon-foote .container .logo .icon-container .icon-box:hover{
  border-radius: 10px;
  color: var(--primary-maroon);
  font-size: 27px;
}

.icon-foote .container .logo .img-bk img {
  width: 290px;
  /* margin: 0 0 20px 0; */
}


.icon-foote .container .mail h1 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 35px;
  border-top: 1px solid var(--primary-maroon);
  border-bottom: 1px solid var(--primary-maroon);
  padding: 10px 0 ;
}


.icon-foote .container .mail .icon-container .icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-decoration: none;
}

.icon-foote .container .mail .icon-container .icon-box .img-bc {
  font-size: 20px;
  color: var(--color-white);
  transition: all .4s;
}

.icon-foote .container .mail .icon-container .icon-box .text p {
  color: var(--gray-color);
  font-size: 17px;
  margin: 0;
  transition: all .4s;
}

.icon-foote .container .mail .icon-container .icon-box:hover .img-bc {
  color: var(--primary-maroon);
  /* font-size: 19px; */
}

.icon-foote .container .mail .icon-container .icon-box:hover .text p{
  color: var(--color-white);
  /* font-size: 15px; */
}

.icon-foote .container .mail h3 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
}

.icon-foote .container .mail p {
  margin-bottom: 0px;
  color: var(--gray-color);
}

.icon-foote .container .subscribe h1 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 13px;
  border-top: 1px solid var(--primary-maroon);
  border-bottom: 1px solid var(--primary-maroon);
  padding: 10px 0 ;
}


.icon-foote .container .subscribe p a{
  color: var(--gray-color);
  margin-bottom: 5px;
  text-decoration: none;
  transition: all 0.3s;
}

.icon-foote .container .subscribe p a:hover{
  color: var(--color-white);
}


/*footer*/
footer {
  background-color: var(--box-background);
  padding: 10px 0;
}

footer .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--gray-color);
  text-transform: capitalize;
}

footer .container p {
  margin-bottom: 0;
}


footer .container p a{
  margin-left: 8px;
  text-decoration: none;
  transition: all 0.4s;
}

footer .container p a:hover{
  color: var(--primary-maroon);
}