.about{
  padding-top: 110px;
  padding-bottom: 20px ;
}

.lefe{
  display: none;
}
.box-container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px;
}


.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  
} 

.box .left{
  background-color: var(--primary-black);
  border-radius: 50%;
  width: 40px;
  height: 40px; 
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.left {
  margin-top: -40px;
}
svg:not(:root).svg-inline--fa, 
svg:not(:host).svg-inline--fa
{
  font-size: 24px;
  color: var(--color-white);
}

.box .rghte h2{
  color: var( --primary-black);
  font-size: 30px;
  font-weight: 900;
}

.box .rghte p{
  color: var( --primary-black);
}

.box-cot{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 60px;
}

.box-cot .lef img{
  width: 600px;
  height: 330px;
  border-radius: 10px;
}

.box-cot .middil {
  border-left: 1px solid black;
  height: 290px;
}

.rght .up h3{
  color: var(--primary-black);
  text-transform: capitalize;
  font-weight: 900;
}

.rght .up  p{
  color: var(--semi-dark) !important;
  font-size: 20px;
  color: var(--color-white);
}

.rght .mid{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.rght .mid .r{
  text-transform: capitalize;
}

.rght .mid .r h3{
  font-weight: 900;
}

.rght .mid .l{
  text-transform: capitalize;
}

.rght .mid .l h3{
  font-weight: 900;
}

.rght .foot h3{
  font-weight: 900;
}