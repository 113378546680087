@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .icon-footer {
    padding-top: 0px;
    height: 100%;
  }

  .icon-foote .web{
    display: none;
  }

  .icon-foote .mob{
    display: inline-block;
  }

  .icon-foote{
    padding-top: 45px;
  }

  .trow{
    display: flex;
    align-items: center;
  }

  .icon-foote .container .mail h1 {
    margin-bottom: 8px;
  }

  .icon-footer .container {
    gap: 20px;
    flex-direction: column;
  }

  .logo {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  footer .container .lte p {
    display: none;
  }

  .icon-footer .container .logo form {
    width: 250px;
  }

  .icon-footer .container .logo .btm {
    width: 98%;
  }

  footer .container p {
    font-size: 12px;
  }
}

@media (max-width:426px) and (min-width:375px) {
  .icon-footer {
    padding-top: 0px;
    height: 100%;
  }

  .icon-foote .web{
    display: none;
  }

  .icon-foote .mob{
    display: inline-block;
  }


  .icon-foote{
    padding-top: 45px;
  }

  .trow{
    display: flex;
    align-items: center;
  }

  .icon-foote .container .mail h1 {
    margin-bottom: 8px;
  }

  .icon-footer .container {
    gap: 20px;
    flex-direction: column;
  }

  .logo {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  footer .container .lte p {
    display: none;
  }

  .icon-footer .container .logo form {
    width: 250px;
  }

  .icon-footer .container .logo .btm {
    width: 98%;
  }

  footer .container p {
    font-size: 12px;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .icon-footer {
    padding-top: 0px;
    height: 100%;
  }

  .icon-foote .web{
    display: none;
  }

  .icon-foote .mob{
    display: inline-block;
  }

  .icon-foote{
    padding-top: 45px;
  }

  .trow{
    display: flex;
    align-items: center;
  }

  .icon-foote .container .mail h1 {
    margin-bottom: 8px;
  }

  .icon-footer .container {
    gap: 20px;
    flex-direction: column;
  }

  .logo {
    margin-top: -80px;
    margin-bottom: -80px;
  }

  footer .container .lte p {
    display: none;
  }

  .icon-footer .container .logo form {
    width: 250px;
  }

  .icon-footer .container .logo .btm {
    width: 98%;
  }

  footer .container p {
    font-size: 12px;
  }
}

@media (max-width:320px) {}