.offer{
  padding-top: 90px;
}

.offer .hed{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.offer .hed .head{
  border-bottom: 2px solid var(--primary-maroon);
  border-top: 2px solid var(--primary-maroon);
  color: var(--primary-black);
  font-size: 22px;
  font-weight: 700;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.offer .hed h1{
  color: var(--primary-black);
}

.offer .hed p{
  color: var(--primary-black);
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer .body{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  padding-bottom: 10px;
  
}

.offer .body .box1{
  border-radius: 10px;
  background-color: var(--gray-transparent-color);
  width: 600px;
  height: 400px;
  padding: 5px 20px;
  box-shadow: -4px 7px 20px 5px var(--primary-black);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.offer .body .box1 .le{
  width: 50%;
}

.offer .body .box1 .le img{
  width: 90%;
  filter: drop-shadow(-12px 9px 11px var(--primary-black));
}

.offer .body .box1 .re{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.offer .body .box1 .re .up {
  width: 30%; 
  height: 30%; 
  background-color: var(--primary-maroon);
  border-radius: 50%;
  background-size: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}


.offer .body .box1 .re .up p { 
  color: white;
  font-size: 40px;
  margin-bottom: 0;
  rotate:10deg;
}

.offer .body .box1 .re .down{
  width: 100%;
}

.offer .body .box1 .re .down p {
  font-size: 20px;
  color: var(--primary-black);
}

.offer .body .box1 .re .down .hed{
  margin-top: 20px;
  color: white;
  border-bottom: 2px solid var(--primary-maroon);
  border-top: 2px solid var(--primary-maroon);
  color: var(--primary-black);
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer .body .box1 .re .down h3{
  font-weight: 900;
  margin-top: 10px;
}

/* box2 */
.offer .body .box2{
  border-radius: 10px;
  background-color: var(--gray-transparent-color);
  width: 600px;
  height: 400px;
  padding: 5px 20px;
  box-shadow: -4px 7px 20px 5px var(--primary-black);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.offer .body .box2 .le{
  width: 50%;
}

.offer .body .box2 .le img{
  width: 90%;
  filter: drop-shadow(-12px 9px 11px var(--primary-black));
}

.offer .body .box2 .re{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.offer .body .box2 .re .up {
  width: 30%; 
  height: 30%; 
  background-color: var(--primary-maroon);
  border-radius: 50%;
  background-size: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}


.offer .body .box2 .re .up p { 
  color: white;
  font-size: 40px;
  margin-bottom: 0;
  rotate:10deg;  
}

.offer .body .box2 .re .down{
  width: 100%;
}

.offer .body .box2 .re .down p{
  color: var(--primary-black);
  font-size: 20px;
} 

.offer .body .box2 .re .down .hed{
  margin-top: 20px;
  color: white;
  border-bottom: 2px solid var(--primary-maroon);
  border-top: 2px solid var(--primary-maroon);
  color: var(--primary-black);
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer .body .box2 .re .down h3{
  font-weight: 900;
  margin-top: 10px;
}