@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .boxn{
    display: none;
  }
  .boxm{
    display:inline-block;
  }

  .boxffe{
    width: 100%;
  }

  .boxffe .imgeee {
    border-radius: 20px;
    width: 92%;
  }

  .boxffe .conteeeen h2 {
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: 10px;
  }

  .contere{
    width: 420px;
    /* padding: 0 20px !important; */
  }

  .wide{
    text-align: center;
  }

  .heede .cont{
    width: 100%;
  }
  .boxff .conteeen .deescr button{
    font-size: 16px;
  }
} 

@media (max-width:426px) and (min-width:375px) {
  .boxn{
    display: none;
  }
  .boxm{
    display:inline-block;
  }

  .boxffe{
    width: 100%;
  }

  
  .boxffe .imgeee {
    border-radius: 20px;
    width: 92%;
  }
  

  .contere{
    width: 379px;
    padding: 0 20px !important;
  }
  .heede .cont{
    width: 100%;
  }
  .boxff .conteeen .deescr button{
    font-size: 16px;
  }

  .wide{
    text-align: center;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .boxn{
    display: none;
  }
  .boxm{
    display:inline-block;
  }

  .boxffe{
    width: 100%;
  }

  .boxffe .imgeee {
    border-radius: 20px;
    width: 92%;
  }

  .boxffe .conteeeen h2 {
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: 10px;
  }

  .contere{
    width: 340px;
    /* padding: 0 20px !important; */
  }
  .heede .cont{
    width: 100%;
  }
  .boxff .conteeen .deescr button{
    font-size: 16px;
  }

  .wide{
    width: 100%;
    text-align: center;
  }
}

@media (max-width:320px){} 
