.gall{
  background-color: var(--primary-backgroundl);
  padding-top: 10px;
}

.gall .herd h1 {
  /* background-color: red; */
  border-bottom: 2px solid var(--primary-gold);
  border-top: 2px solid var(--primary-gold);
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 700;
  width: 130px;
  margin: 0 46%;
  text-align: center;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  color: var(--primary-gold) !important;
  /* background-color: var(--primary-maroon) !important; */
  border-radius: 50%;
  width: 150px;
}


.swiper-pagination-bullet-active{
  background-color: var(--primary-gold);
  padding-top: 5px !important;

}

.gal-im{
  height: 700px;
  text-align: center;
}

.gal-im img{
  border-radius: 25px;
  width: 60%;
  height: 94%;
  padding-top: 10px;
  padding-bottom: 10px;
}

