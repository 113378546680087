@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .menlist h1 {
    margin: 0 25%;
    margin-bottom: 20px;
  }

 
  .menlist .ru {
    width: 100%;
    height: 340px;
    padding: 5px 20px;
  }

  .menlist .ru img{
    width: 80%;
    height: 89%;
    margin: 0 8%
  }

  
  .meli {
    margin-bottom: 20px;
    width: 380px;
  }

  .title{
    margin-bottom: 10px;
  }
  .title p {
    font-size: 18px;
    padding-right: 12px;
  }

  .title button {
    font-size: 13px;
    width: 100px;
    height: 35px;
  }
} 

@media (max-width:426px) and (min-width:375px) {
  .menlist h1 {
    margin: 0 25%;
    margin-bottom: 20px;
  }

  .menlist .ru {
    width: 100%;
    height: 340px;
    padding: 5px 20px;
  }

  .menlist .ru img{
    width: 86%;
    height: 89%;
    margin: 0 7%
  }

  
  .meli {
    margin-bottom: 20px;
    width: 350px;
  }

  .title{
    margin-bottom: 10px;
  }
  .title p {
    font-size: 18px;
    padding-right: 12px;
  }

  .title button {
    font-size: 13px;
    width: 100px;
    height: 35px;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .menlist h1 {
    margin: 0 25%;
    margin-bottom: 20px;
  }

  .menlist .ru {
    width: 100%;
    height: 340px;
    padding: 5px 20px;
  }

  .menlist .ru img{
    width: 86%;
    height: 89%;
    margin: 0 7%
  }

  .meli {
    margin-bottom: 20px;
    width: 320px;
  }

  .title{
    margin-bottom: 10px;
  }
  .title p {
    font-size: 18px;
    padding-right: 12px;
  }

  .title button {
    font-size: 13px;
    width: 100px;
    height: 35px;
  }
}

@media (max-width:320px){ 

} 
