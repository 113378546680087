@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .offer{
    padding-top: 60px;
  }

  .offer .hed p{
    width: 100%;
  }
  
  .offer .body .box1
  ,.offer .body .box2
  {
    border-radius: 10px;
    background-color: var(--gray-transparent-color);
    width: 350px;
    height:250px;
    padding: 20px 5px;
  }
  

  .offer .body .box1 .le,
  .offer .body .box2 .le{
    width: 40%;
  }

  .offer .body .box1 .re .up,
  .offer .body .box2 .re .up {
    width: 30%; 
    height: 15%; 
    padding: 10px;
  }

  .offer .body .box1 .re .up p,
  .offer .body .box2 .re .up p { 
    font-size: 20px;
  }

  .offer .body .box1 .re .down .hed,
  .offer .body .box2 .re .down .hed{
    margin-top: 10px;
    color: white;
    border-bottom: 2px solid var(--primary-maroon);
    border-top: 2px solid var(--primary-maroon);
    color: var(--primary-black);
    font-size: 16px;
  }

  .offer .body .box1 .re .down h3,
  .offer .body .box2 .re .down h3{
    margin-top: 0;
    margin-bottom: 0;
  }

  .offer .body .box1 .re .down p, 
  .offer .body .box2 .re .down p{
    font-size: 16px;
  }
} 

@media (max-width:426px) and (min-width:375px) {
  .offer{
    padding-top: 60px;
  }

  .offer .hed p{
    width: 100%;
  }
  
  .offer .body .box1
  ,.offer .body .box2
  {
    border-radius: 10px;
    background-color: var(--gray-transparent-color);
    width: 350px;
    height:250px ;
    padding: 20px 5px;
  }
  

  .offer .body .box1 .le,
  .offer .body .box2 .le{
    width: 40%;
  }

  .offer .body .box1 .re .up,
  .offer .body .box2 .re .up {
    width: 30%; 
    height: 15%; 
    padding: 10px;
  }

  .offer .body .box1 .re .up p,
  .offer .body .box2 .re .up p { 
    font-size: 20px;
  }

  .offer .body .box1 .re .down .hed,
  .offer .body .box2 .re .down .hed{
    margin-top: 10px;
    color: white;
    border-bottom: 2px solid var(--primary-maroon);
    border-top: 2px solid var(--primary-maroon);
    color: var(--primary-black);
    font-size: 16px;
  }

  .offer .body .box1 .re .down h3,
  .offer .body .box2 .re .down h3{
    margin-top: 0;
    margin-bottom: 0;
  }

  .offer .body .box1 .re .down p, 
  .offer .body .box2 .re .down p{
    font-size: 16px;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .offer{
    padding-top: 60px;
  }

  .offer .hed p{
    width: 100%;
  }
  
  .offer .body .box1
  ,.offer .body .box2
  {
    border-radius: 10px;
    background-color: var(--gray-transparent-color);
    width: 350px;
    height:250px;
    padding: 20px 5px;

  }
  

  .offer .body .box1 .le,
  .offer .body .box2 .le{
    width: 40%;
  }

  .offer .body .box1 .re .up,
  .offer .body .box2 .re .up {
    width: 30%; 
    height: 15%; 
    padding: 10px;
  }

  .offer .body .box1 .re .up p,
  .offer .body .box2 .re .up p { 
    font-size: 20px;
  }

  .offer .body .box1 .re .down .hed,
  .offer .body .box2 .re .down .hed{
    margin-top: 10px;
    color: white;
    border-bottom: 2px solid var(--primary-maroon);
    border-top: 2px solid var(--primary-maroon);
    color: var(--primary-black);
    font-size: 16px;
  }

  .offer .body .box1 .re .down h3,
  .offer .body .box2 .re .down h3{
    margin-top: 0;
    margin-bottom: 0;
  }

  .offer .body .box1 .re .down p, 
  .offer .body .box2 .re .down p{
    font-size: 16px;
  }
}

@media (max-width:320px){ 
  
} 
