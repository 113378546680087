

@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  /*------------------navbar-----------------*/
  .navbar-toggler{
    display: inline-block;
  }

  .navbar-nav-con{
    display: none;
  }

  .navbare-upper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row; 
    gap: 10px;
    height: 140px;
    margin-bottom: 15px;
  }

  .navbar-upper {
    height: 100px;
  }
  
  .navbar-upper .btn,
  .navbar-upper .deliver {
    display: none;
  } 

  .navbar-upper img{
    width: 180px;
    height: 140px;
  }

  .navbare-upper button{
    width: 140px;
    height: 66px;
  }
} 

@media (max-width:426px) and (min-width:375px) {
/*------------------navbar-----------------*/
  .navbar-toggler{
    display: inline-block;
  }

  .navbar-nav-con{
    display: none;
  }

  .navbare-upper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row; 
    gap: 10px;
    height: 140px;
    margin-bottom: 15px;
  }

  .navbar-upper {
    height: 100px;
  }

  .navbar-upper .btn,
  .navbar-upper .deliver {
    display: none;
  } 

  .navbar-upper img{
    width: 160px;
    height: 140px;
  }

  .navbare-upper button{
    width: 140px;
    height: 66px;
  }
}

@media (max-width:375px) and (min-width:320px) {
/*------------------navbar-----------------*/
  .navbar-toggler{
    display: inline-block;
  }

  .navbar-upper {
    height: 100px;
  }
  .navbar-nav-con{
    display: none;
    border: none ;
  }

  .navbare-upper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row; 
    gap: 10px;
    height: 140px;
    margin-bottom: 15px;
  }

  .navbar-upper .btn,
  .navbar-upper .deliver {
    display: none;
  } 

  .navbar-upper img{
    width: 180px;
    height: 140px;
  }

  .navbare-upper button{
    width: 140px;
    height: 66px;
  }
}

@media (max-width:320px){ 
  
} 
