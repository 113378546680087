@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .mobd{
    display: none;
  }

  .mobv{
    width: 100%;
    display: inline-block;
  }

  .rhe p{
    padding: 0 27px;
  }
}

@media (max-width:426px) and (min-width:375px) {
.mobd{
    display: none;
  }

  .mobv{
    width: 100%;
    display: inline-block;
  }

  .rhe p{
    padding: 0 27px;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .mobd{
    display: none;
  }

  .mobv{
    width: 100%;
    display: inline-block;
  }

  .rhe p{
    font-size: 15px;
    padding: 0 16px;
  }
}

@media (max-width:320px) {}