.headertt {
  background-color: var(--background);
  color: #fff;
  text-align: center;
  padding: 40px 0;
}

.headertt img {
  height: 120px;
  width: 530px !important;
}