@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  header {
    padding-bottom: 20px;
  }

  .back-mobile{
    display: block;
    margin-bottom: 0px;
    padding-top: 120px;
  }

  .hedete {
    display: none;
    background-color: var(--primary-backgroundl) !important;
    margin-bottom: 0px;
    padding-top: 120px;
  }
  
  .left {
    padding: 0 20px;
    margin-top: -100px;
  }

  .left h1 {
    font-size: 30px;
    width: 100%;
    padding-bottom: 10px;
  }

  .content {
    margin-top: -90px;
  }

  .left p {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }

  .left button {
    width: 140px;
    height: 66px;
  }

  .right {
    display: none;
  }


  .right img {
    display: none;
  }
}

@media (max-width:426px) and (min-width:375px) {
  .back-mobile{
    display: block;
    margin-bottom: 0px;
    padding-top: 120px;
  }

  header {
    padding-bottom: 20px;
  }
  
  .left {
    padding: 0 20px;
    margin-top: -100px;
  }

  .left h1 {
    font-size: 30px;
    width: 100%;
    line-height: 45px;
  }

  .content {
    margin-top: -90px;
  }

  .hedete {
    display: none;
    background-color: var(--primary-backgroundl) !important;
    margin-bottom: 0px;
    padding-top: 120px;
  }

  .left p {
    font-size: 18px;
    width: 100%;
    text-align: center;

  }

  .left button {
    width: 140px;
    height: 66px;
  }

  .right {
    display: none;
  }


  .right img {
    display: none;
  }
}

@media (max-width:375px) and (min-width:320px) {
  header {
    padding-bottom: 20px;
  }

  .back-mobile{
    display: block;
    /* margin-top: 90px; */
    padding-top: 120px;
  }

  .hedete {
    display: none;
    background-color: var(--primary-backgroundl) !important;
    margin-bottom: 0px;
    padding-top: 120px;
  }

  .left {
    padding: 0 20px;
    margin-top: -100px;
  }

  .left h1 {
    font-size: 30px;
    width: 100%;
    line-height: 45px;
  }

  .content {
    margin-top: -90px;
  }

  .left p {
    font-size: 18px;
    width: 100%;
    text-align: center;

  }

  .left button {
    width: 140px;
    height: 66px;
  }

  .right {
    display: none;
  }


  .right img {
    display: none;
  }
}

@media (max-width:320px) {}