.testimonials{
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: var(--secondary-background);
  /* height: 500px; */
}

.testimonials .contain .head{
  border-bottom: 2px solid var(--primary-gold);
  border-top: 2px solid var(--primary-gold);
  color: var(--color-white);
  font-size: 22px;
  font-weight: 700;
  width: 150px;
  text-align: center;
  margin: 0 43%;
  margin-bottom: 20px;
}

.testimonials .contain h1{
  color: var(--color-white);
}

.testimonials .contain p{
  color: var(--color-white);
}