.navBar{
  background-color: var(--secondary-background);
  /* background-color: red; */

}

.navbar .contain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.btn-close{
  background-image: none !important;
}
/*------------------------tooggeler---------------------------------*/

.navbar-toggler{
  display: none;
}

.offcanvas{
  width: 100% !important;
  height: 100%;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

svg:not(:root).svg-inline--fa, 
svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  color: white;
  font-size: 30px;
}

.navbare-upper button{
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var(--semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 232px;
  height: 80px;
  overflow: hidden;
}
  
.navbare-upper button:hover{
  background-color: var(--color-dark);
  color: var(--primary-green);
  box-shadow: 0 0 0px var();
  padding-bottom: 0px;
} 

.navBar-nave{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:30px;
  margin-bottom: 0;
} 

.navbar-nav-soc{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:70px;
  margin-bottom: 0;
}

.navbar-nav-soc .nav-link-soc{
  color: var(--color-white);
  font-size: 24px;
  transition: all 0.3s;
}

.navbar-nav-soc .nav-link-soc:hover{
  color: var(--gray-color);
} 

/*----------------desktop--style--------------------------------------------------*/
.navbar-upper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  gap: 80px;
}

.navbar-upper .btn{
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var( --semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 232px;
  height: 80px;
  overflow: hidden;
}
  
.navbar-upper .btn:hover{
  background-color: var(--primary-gold);
  color: var(--primary-maroon);
  box-shadow: 0 0 0px var();
  padding-bottom: 0px;
}


.navbar-upper img{
  width: 310px;
  height: 188px;
  filter: brightness(0.7);
}

.deliver {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.deliver{
  color: var(--color-white);
  font-size: 24px;
  transition: all 0.3s;
}

.deliver:hover{
  color: var(--gray-color);
  font-size: 23px;
}

.navbar-nav-con{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
  border-top: 2px solid  var(--primary-gold);
  border-bottom: 2px solid  var(--primary-gold);
  padding: 35px 0;
  height: 40px;
} 

.navBar-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:30px;
  margin-bottom: 0;
} 

.nav-item{
  color: var(--color-white);
  transition: all 0.3s;
  text-transform: capitalize;
  font-size: 20px;
}

.nav-item:hover{
  color: var(--primary-gold);
  /* letter-spacing: 2px; */
}

.navBar-nave{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:30px;
  margin-bottom: 0;
} 

.navbar-nave-soc{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:70px;
  margin-bottom: 0;
}

svg:not(:root).svg-inline--fa, 
svg:not(:host).svg-inline--fa {
  font-size: 24px;
  color: var(--color-white);
  transition: all 0.3s;
}


svg:not(:root).svg-inline--fa:hover, 
svg:not(:host).svg-inline--fa:hover {
  font-size: 24px;
  color: var(--primary-gold);
}

