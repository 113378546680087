@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  #choose {
    height: 350px;
  }

  .card-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .carde .loc {
    width: 197px;
    height: 120px;
  }

  .carde .cow {
    width: 80px;
    padding-bottom: 30px;
  }
}

@media (max-width:426px) and (min-width:375px) {
  #choose {
    height: 350px;
  }

  .card-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .carde .loc {
    width: 175px;
    height: 120px;
  }

  .carde .cow {
    width: 80px;
    padding-bottom: 30px;
  }
}

@media (max-width:375px) and (min-width:320px) {
  #choose {
    height: 350px;
  }

  .card-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .carde .loc {
    width: 165px;
    height: 120px;
  }

  .carde .cow {
    padding-bottom: 30px;
    width: 80px;
  }
}

@media (max-width:320px) {}