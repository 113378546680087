.MapCh{
  background-color: var(--gray-color);
  padding: 40px 0;
}

.MapCh .hed h1{
  text-align: center;
  margin-bottom: 20px;
  color: var(--color-white);
}

.mapee{
  text-align: center;
}
.icon-card a{
  display: flex;
  align-items: center;
  gap: 30px;
  text-decoration: none;
  padding: 0 40px;
  padding-top: 20px;
}

.icon-card a .txt-side{
  text-decoration: none;
  color: var(--color-white);
  transition: all 0.3s;
  font-size: 20;
  margin-bottom: 0;
}

.icon-card a:hover {
  color: var(--primary-gold) !important;
}
.icon-card a:hover .txt-side{
  color: var(--primary-gold) !important;
}

