@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .menu .content .cont {
    font-size: 18px;
    width: 100%;
  }
  
  .boxf-cont{
    width: 100%;
  }

  .boxf .imge img{
    width: 100%;
    height: 100%;
  }

  .menu {
    padding-top: 90px;
  }

  .boxf {
    text-align: center;
    width: 96%;
  }
}

@media (max-width:426px) and (min-width:375px) {
  .menu .content .cont {
    font-size: 18px;
    width: 100%;
  }
  
  .boxf-cont{
    width: 100%;
  }

  .boxf .imge img{
    width: 100%;
    height: 100%;
  }

  .menu {
    padding-top: 90px;
  }

  .boxf {
    text-align: center;
    width: 96%;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .menu .content .cont {
    font-size: 18px;
    width: 100%;
  }
  
  .boxf-cont{
    width: 100%;
  }

  .boxf .imge img{
    width: 100%;
    height: 100%;
  }

  .menu {
    padding-top: 90px;
  }

  .boxf {
    text-align: center;
    width: 95%;
  }
}

@media (max-width:320px) {}