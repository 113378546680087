@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .testimonials .contain .head {
    margin: 0 30%;
    margin-bottom: 20px;
  }
} 

@media (max-width:426px) and (min-width:375px) {
  .testimonials .contain .head {
    margin: 0 30%;
    margin-bottom: 20px;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .testimonials .contain .head {
    margin: 0 30%;
    margin-bottom: 20px;
  }
}

@media (max-width:320px){ 
} 