.menuClass{
  background-image: url(../images/background/Backgroundsec.webp);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
  z-index: 2;
}

.heede .heed {
  border-bottom: 2px solid var(--primary-maroon);
  border-top: 2px solid var(--primary-maroon);
  color: var(--primary-black);
  font-size: 22px;
  font-weight: 700;
  width: 110px;
  text-align: center;
  z-index: 3;
  position: relative;
}

.heede{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 15px;
  padding-top: 20px;
  z-index: 3;
  position: relative;
}

.heede h1{
  font-weight: 900; 
  margin-bottom: 0;
  z-index: 3;
  position: relative;
  border-bottom: 2px solid var(--primary-gold);
  border-top: 2px solid var(--primary-gold);
  color: var(--color-white);
}

.heede .cont{
  font-size: 20px;
  margin-bottom: 0;
  width: 610px;
  padding: 0 10px;
  z-index: 3;
  position: relative;
}

.boxm{
  display: none;
}
.boxn{
  display: inline-block;
}

.conter{
  z-index: 3;
  position: relative;
}

.conter{
  gap:10px;
}

.boxff{
  /* width: 150px; */
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid var(--primary-gold);
  padding: 0  !important;
  border-radius: 20px;
  background-image: url(../images/background/Background-min.webp);
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
}

.boxff .imgee{
  border-radius: 20px;
  width: 100%;
}

.boxff .imgee img{
  border-radius: 19px;
  width: 100%;
  height: 210px;
}

.boxff .conteeen  {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 30px;
  padding-bottom: 10px;
  width: 100%;
}

.wide{
  width: 100%;
}
.boxff .conteeen  h2{
  margin-bottom: 0;
  font-size: 18px;
  color: var(--color-white);
  text-align: center;
}

.boxff .conteeen button{
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var(--semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 90px;
  height: 40px;
  overflow: hidden;
  text-align: center;
}

.boxff .conteeen button:hover{
  background-color: var(--primary-gold);
  color: var(--primary-green);
  padding-bottom: 0px;
}

/*swiper*/
.contere{
  z-index: 3;
  position: relative;
  width: 377px;
  height: 240px;
}

.boxffe{
  width: 120px;
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid var(--primary-gold);
  padding: 0  !important;
  border-radius: 20px;
  background-image: url(../images/background/Background-min.webp);
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
}

.boxffe .imgeee{
  text-align: center;
  border-radius: 20px;
  width: 100%;
}

.boxffe .imgeee img{
  border-radius: 19px;
  width: 95%;
  height: 120px;
}

.boxffe .conteeeen  {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}

.boxffe .conteeeen  h2{
  margin-bottom: 0;
  font-size: 18px;
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
}

.boxffe .conteeeen button{
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var(--semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 90px;
  height: 40px;
  overflow: hidden;
  text-align: center;
}

.boxffe .conteeeen button:hover{
  background-color: var(--primary-gold);
  color: var(--primary-green);
  padding-bottom: 0px;
}