.back-mobile{
  background-image: url(../images/Front-VIew.webp);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: none;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Adjust the alpha value to control the darkness */
  z-index: 2;
  position: relative;
}

.hedete{
  background-color: var(--primary-backgroundl) !important;
  margin-bottom: 50px;
}

.content{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px;
  z-index: 2;
  position: relative;
}

/* left */
.left{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 35px;
}

.left h1{
  text-align: center;
  width: 420px;
  color: var(--color-white) !important;
  font-size: 70px;
}

.left p{
  font-size: 18px;
  width: 280px;
  color: var(--color-white);
}

.left button{
  font-size: 20px;
  width: 232px;
  height: 86px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var(--semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 232px;
  height: 80px;
  overflow: hidden;
}
  
.left button:hover{
  background-color: var(--primary-gold);
  color: var(--primary-green);
  border: 3px solid  var(--primary-gold);
  box-shadow: 0 0 0px var();
  padding-bottom: 0px;
}

/* right */
.right{
  border: 20px solid var(--primary-background);
  border-radius: 50% 50% 0% 0%;
  height: 530px;
}


.right img{
  height: 550px;
  width: 400px;
  border-radius: 45% 45% 0% 0%;
}