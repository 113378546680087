.box1{
  background-color: var(--semi-dark);
  height: 250px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 10px;
}

.box1 .up{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-left: 180px;
}

.box1 .up .le img{
  border-radius: 50%;
  width:  50px;
  height: 50px;
}

.box1 .up .ri h2{
  color: var(--color-white);
  font-size: 20px;
  margin-bottom: 0;
  padding-top: 10px;
  
}

.box1 .middle {
  border-top: 3px solid var(--primary-gold);
  width: 290px;
  margin-bottom: 0;
}

.box1 .down p{
  color: var(--color-white);
  font-size: 40px;
}
