
.menuClassss{
  z-index: 3;
  position: relative;
  box-sizing: border-box;
}

/*swiper*/
.conteree{
  margin-top: 30px;
  z-index: 4;
  position: relative;
  width: 1240px;
  height: 300px;
}

.boxffes{
  width: 160px;
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid var(--primary-gold);
  padding: 0  !important;
  border-radius: 20px;
  background-image: url(../images/background/Background-min.webp);
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
}

.wides{
  text-align: center;
}

.boxffes .imgeees{
  text-align: center;
  border-radius: 20px;
  width: 100%;
}

.boxffes .imgeees img{
  border-radius: 19px;
  width: 95%;
  height: 130px;
}

.boxffes .conteeeens  {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
}

.boxffes .conteeeens  h2{
  margin-bottom: 0;
  font-size: 18px;
  color: var(--color-white);
  text-decoration: none;
  text-align: center;
}

.boxffes .conteeeens button{
  font-size: 12px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var(--semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 90px;
  height: 40px;
  overflow: hidden;
  text-align: center;
}

.boxffes .conteeeens button:hover{
  background-color: var(--primary-gold);
  color: var(--primary-green);
  padding-bottom: 0px;
}