
.mobd{
  display: inline-block;
  width: 100%;

}

.mobv{
  display: none;
}
/*icon-footer*/
.icon-footer {
  background-color: var(--secondary-background);
  padding-top: 40px ;
  padding-bottom: 30px ;
  z-index: 9;
}


.logo,
.mail,
.subscribe {
  width: 40%;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo .icon-container {
  display: flex;
  align-items: center;
  gap: 25px;
}

.logo .icon-container .icon-boxx {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  transition: all .4s;
  color: var(--color-white);
}


.logo .icon-container .icon-box:hover{
  color: var(--primary-green);
  font-size: 27px;
}

.logo .img-bk img {
  width: 300px;
  padding-top: 20px;
}


.mail h1 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 35px;
  border-top: 1px solid var(--primary-gold);
  border-bottom: 1px solid var(--primary-gold);
  padding: 10px 0 ;
}

.mail .icon-container .icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-decoration: none;
}

.mail .icon-container .icon-box .img-bc {
  font-size: 20px;
  color: var(--color-white);
  transition: all .4s;
}

.mail .icon-container .icon-box .text p {
  color: var(--gray-color);
  font-size: 17px;
  margin: 0;
  transition: all .4s;
}

.mail .icon-container .icon-box:hover .img-bc {
  color: var(--primary-maroon);
  /* font-size: 19px; */
}

.mail .icon-container .icon-box:hover .text p{
  color: var(--color-white);
  /* font-size: 15px; */
}

.mail h3 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
}

.mail p {
  margin-bottom: 0px;
  color: var(--gray-color);
}

.subscribe h1 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  border-top: 1px solid var(--primary-gold);
  border-bottom: 1px solid var(--primary-gold);
  padding: 5px 0 ;
}


.subscribe .txx {
  margin-bottom: 2px;
  text-align: center;
}

.subscribe .txx  li a {
  color: var(--gray-color);
  text-decoration: none;
  margin-bottom: 0px;
  transition: all 0.3s;
  font-size: 19px;
}

.subscribe .txx  li a:hover{
  color: var(--color-white);
}

/*footer*/
footer {
  background-color: var(--box-background);
  padding: 10px 0;
}

footer .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--gray-color);
  text-transform: capitalize;
  
}

footer .container p {
  margin-bottom: 0 10px;
  text-align: center;
 
}


footer .container p a{
  margin-left: 8px;
  text-decoration: none;
  transition: all 0.4s;
}

footer .container p a:hover{
  color: var(--primary-maroon);
}


/*mobile-view*/
.icon-footerr {
  background-color: var(--secondary-background);
  z-index: 9;
}

.logoo,
.maill {
  width: 40%;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logoo .icon-container {
  display: flex;
  align-items: center;
  gap: 25px;
}

.logoo .icon-container .icon-boxx {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  transition: all .4s;
  color: var(--color-white);
}


.logoo .icon-container .icon-box:hover{
  color: var(--primary-green);
  font-size: 27px;
}

.logoo .img-bk img {
  width: 300px;
}


.maill h1 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 35px;
  border-top: 1px solid var(--primary-gold);
  border-bottom: 1px solid var(--primary-gold);
  padding: 10px 0 ;
}

.maill .icon-container .icon-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
  text-transform: capitalize;
  text-decoration: none;
}

.maill .icon-container .icon-box .img-bc {
  font-size: 20px;
  color: var(--color-white);
  transition: all .4s;
}

.maill .icon-container .icon-box .text p {
  color: var(--gray-color);
  font-size: 20px;
  margin: 0;
  transition: all .4s;
}

.maill .icon-container .icon-box:hover .img-bc {
  color: var(--primary-maroon);
  /* font-size: 19px; */
}

.maill .icon-container .icon-box:hover .text p{
  color: var(--color-white);
  /* font-size: 15px; */
}

.maill h3 {
  color: var(--color-white);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
}

.maill p {
  margin-bottom: 0px;
  color: var(--gray-color);
}