.choheader{
  background-color: var(--gray-color);
  padding: 5px 0;
}

.choheader .lec {
  height: 700px;
}

.chosgal{
  height: 90%;
  width: 100%;
  display: flex;
  margin: 5% auto 0;
  box-sizing: border-box;
}

.img-box{
  margin: 0 1%;
  flex-grow: 1 ;
  flex-basis: 0;
  transition: all 0.5s;
}

.img-box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s;
  border-radius: 25px;
}

.img-box:hover{
  flex-basis: 50%;
}

.rec {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
}

.rec h1{
  color: var(--color-white);
}

.rec .btnc{
  width: 160px;
  height: 50px;
  border-radius: 18px;
  font-size: 20px;
  border: 1px solid var(--primary-gold);
  background-color: var(--primary-gold);
  color: var(--color-white);
  transition: all 0.3s;
}

.rec .btnc:hover{
  border: 1px solid var(--primary-gold);
  background-color: var(--color-white);
  color: var(--primary-black);
}