

.menu .content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  
} 

.menu .content .hed {
  border-bottom: 2px solid var(--primary-gold);
  border-top: 2px solid var(--primary-gold);
  color: var(--primary-black);
  font-size: 22px;
  font-weight: 700;
  display: flex;
  width: 130px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.menu .content h1{
  font-weight: 900; 
  margin-bottom: 0;
}

.menu .content .cont{
  font-size: 20px;
  margin-bottom: 0;
  width: 610px;
  padding: 0 10px;
}

.boxf-cont{
  width: 100%;
  gap: 20px;
}

.boxf{
  text-align: center;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  /* width: 250px !important; */
}

.boxf .imge img{
  border-radius: 20px;
  width: 250px;
  height: 250px;
}

.boxf .conten .heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  width: 250px;
}

.boxf .conten .heading h3{
  margin-bottom: 0;
}


.boxf .conten .heading p{
  margin-bottom: 0;
}

.boxf .conten .middle {
  border-top: 3px solid var(--primary-maroon);
  width: 250px;
  opacity: 0.3;
  margin-bottom: 0;
}

.boxf .conten  .descr p{
  width: 250px;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 700;
}