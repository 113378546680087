#choose {
  padding: 2rem 1rem;
  text-align: center;
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

#choose h1 {
  font-size: 30px;
  font-weight: 900;
}

#choose p {
  font-size: 20px;
  font-weight: 900;
}

.card-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  gap: 100px;
}

.carde {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 50px;
  border-radius: 25px;
  transition: all 0.5s;
  border: none;
  text-decoration: none;
  background-color: var(--gray-transparent-color);
}

.carde .loc {
  /* border-radius: 0; */
  width: 100%;
  height: 500px;
  border: none;
  position: relative;
  transition: all 0.5s;
  border-radius: 25px;
}

.carde .cow {
  position: absolute;
  width: 150px;
  display: none;
  transition: all 0.5s;
}

.carde .body p {
  margin-bottom: 0;
  text-transform: capitalize;
  color: var(--primary-black);
}

.carde:hover {
  margin-top: 3px;
}

.carde:hover img {
  filter: blur(5px);
}

.carde:hover .cow {
  display: inline-block;
  filter: blur(0px);
  filter: var(--filter-white);
}

.carde:hover .body p {
  color: var(--semi-dark);
}
