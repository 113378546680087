.menlist{
  padding: 20px 0;
  background-image: url(../images/background/Backgroundsec.webp);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha value to control the darkness */
  z-index: 2;
}

.menlist h1{
  margin: 10px 43% 30px 43%;
  border-bottom: 2px solid var(--primary-gold);
  border-top: 2px solid var(--primary-gold);
  color: var(--color-white);
  text-transform: capitalize;
  /* font-size: 22px; */
  font-weight: 900;
  width: 180px;
  text-align: center;
  z-index: 3;
  position: relative;
}

.descre{
  font-size: 20px;
  margin-bottom: 0;
  color: var(--primary-gold);
}

.menlist .ru{
  width: 350px;
  height: 350px;
  z-index: 3;
  position: relative;
}

.menlist .ru img{
  width: 100%;
  height: 100%;
  animation: upAndDown 2s ease-in-out infinite;
}

/*list*/
.lu{
  padding: 0 10px;
  z-index: 3;
  position: relative;
}
.meli{
  margin-bottom: 20px;
  width: 850px;
}
.title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title p{
  color: var(--color-white);
  font-size: 25px;
  margin-bottom: 0;
}

.title .btnmen{
  font-size: 15px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--primary-gold);
  background-color: var( --semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 120px;
  height: 40px;
  overflow: hidden;
}

.title button:hover{
  background-color: var(--primary-gold);
  color: var(--primary-maroon);
  box-shadow: 0 0 0px var();
  padding-bottom: 0px;
}

.descrr{
  text-align: center;
}

.descrr p{
  font-size: 15px;
  margin-bottom: 0;
  color: var(--primary-gold);
}

/*animation*/
@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}