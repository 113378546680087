@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {

  .about {
    padding-top: 50px;
  }

  .lefe {
    display: inline-block;
    margin-bottom: 20px;
  }

  .lefe img {
    width: 100%;
    border-radius: 10px;
  }

  .left {
    margin-top: -30px;
  }

  .box-container {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }

  .box {
    padding-top: 0;
    width: 100%;
  }

  .rghte {
    width: 50%;
  }

  .rght .mid {
    flex-direction: column;
  }

  .rght .mid p {
    font-size: 18px;
  }

  .middil,
  .lef {
    display: none;
  }

  .rght .foot h3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:426px) and (min-width:375px) {

  .about {
    padding-top: 50px;
  }

  .lefe {
    display: inline-block;
    margin-bottom: 20px;
  }

  .lefe img {
    width: 100%;
    border-radius: 10px;
  }

  .left {
    margin-top: -30px;
  }

  .box-container {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }

  .box {
    padding-top: 0;
    width: 100%;
  }

  .rghte {
    width: 50%;
  }

  .rght .mid {
    flex-direction: column;
  }

  .rght .mid p {
    font-size: 18px;
  }

  .middil,
  .lef {
    display: none;
  }

  .rght .foot h3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .about {
    padding-top: 50px;
  }

  .lefe {
    display: inline-block;
    margin-bottom: 20px;
  }

  .lefe img {
    width: 100%;
    border-radius: 10px;
  }

  .left {
    margin-top: -30px;
  }

  .box-container {
    margin-top: 20px;
    flex-direction: column;
    gap: 10px;
  }

  .box {
    padding-top: 0;
    width: 100%;
  }

  .rghte {
    width: 50%;
  }

  .rght .mid {
    flex-direction: column;
  }

  .rght .mid p {
    font-size: 18px;
  }

  .middil,
  .lef {
    display: none;
  }

  .rght .foot h3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:320px) {}