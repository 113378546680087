@font-face {
  font-family: "ElMessiri";
  src: url(../asseet/font/ElMessiri-Bold.woff2);
  font-weight: 900;
}
@font-face {
  font-family: "ElMessiri";
  src: url(../asseet/font/ElMessiri-Medium.woff2);
  font-weight: 700;
}
@font-face {
  font-family: "ElMessiri";
  src: url(../asseet/font/ElMessiri-Regular.woff2);
  font-weight: 500;
}
* {
  margin: 0;
  font-family: "ElMessiri", sans-serif;
  background-color: var(--background-color);

}

:root {
  --primary-backgroundl:    #645d55eb;
  --primary-background:     #594f45;
  --primary-gold:           #b9945f;
  --primary-red:            #ff0000;
  --primary-black:          #000000; 
  --primary-green:          #2c6a51; 
  --primary-maroon:         #993333; 
  --primary-black:          #000000; 
  --background-light:       #f5f5f5;
  --secondary-background:   #25282A;  
  --box-background:         #1E1E1E;
  --background:             #343a40;
  --background-hover:       #495057;
  /* --color-hover:          #192f44; */
  --primary-color:          #0080FF;
  --secondary-color:        #393f43;
  --color-blue:             #3498da;
  --semi-dark:              #2f3539b4;
  --color-transparent-dark: #0000006d;
  --color-white:            #fff;
  --gray-color:             #808080;
  --gray-transparent-color: #9e9e9e73;
  --red-color:              #F0644B;
  --color-green:            #15a184;

  --filter-white:   invert(100%) sepia(100%) saturate(0%) hue-rotate(67deg) brightness(106%) contrast(101%);
  --filter-gray:    invert(55%)  sepia(0%)  saturate(2121%) hue-rotate(12deg) brightness(90%) contrast(97%);
  --filter-red:     invert(56%)  sepia(48%) saturate(4758%) hue-rotate(333deg) brightness(101%) contrast(88%);
  --filter-green:   invert(46%)  sepia(86%) saturate(396%) hue-rotate(118deg) brightness(93%) contrast(95%);
  --filter-primary: invert(29%)  sepia(25%) saturate(6283%) hue-rotate(198deg) brightness(98%) contrast(84%);
}

ul {
  list-style: none;
  padding: 0 !important;
}

a {
  text-decoration: none;
}

/*
@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {} 

@media (max-width:426px) and (min-width:375px) {}

@media (max-width:375px) and (min-width:320px) {}

@media (max-width:320px){} 
*/