.for{
  background-image: url(../images//Hall.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
  padding-top: 90px;
  width: 100%;
  /* padding: 50px 0; */
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

/* Overlay Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
  z-index: 2;
}

.for .con{
  position: relative;
  z-index: 3;
  background-color: var(--semi-dark);
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
}


.for .con .hed{
  border-bottom: 2px solid var(--primary-maroon);
  border-top: 2px solid var(--primary-maroon);
  color: var(--color-white);
  font-size: 22px;
  font-weight: 700;
  width: 80px;
  z-index: 3;
}

.for .con h1{
  color: var(--color-white);
  margin-bottom: 15px;
  z-index: 3;
}

.conten{
  height: 330px;
  padding: 0 130px;
}

.for .cont{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 80px;
}

.conten .inbut-f {
  background-color: var(--color-transparent-dark);
  width: 95%;
  height: 100%;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conten .inbut-f input {
  border: none;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border-radius: 10px;
  transition: all 0.5s;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-white);
}

.conten .inbut-f input:hover{
  border: 2px solid var(--primary-gold);
  box-shadow: 0px 0px 19px 5px var(--primary-green) ;
}

.conten .inbut-f input:hover::-webkit-input-placeholder{
  color: var(--color-white);
  letter-spacing: 1px;
}

.contt{
  display: flex;
  justify-content: center;
}

.contt #message {
  background-color: var(--color-transparent-dark);
  border: none;
  width: 95%;
  height: 90%;
  padding: 0 10px;
  border-radius: 10px;
  transition: all 0.5s;
  font-size: 20px;
  font-weight: 700;
  color: var(--color-white);
}

.contt #message:hover{
  border: 2px solid var(--primary-gold);
  box-shadow: 0px 0px 19px 5px var(--primary-green) ;
}

.cont #message:hover::-webkit-input-placeholder{
  color: var(--color-white);
  letter-spacing: 1px;
}

form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

form button{
  margin: 20px 0;
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 5px;
  color: var(--color-white);
  background-color: var( --semi-dark);
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s;
  border: 3px solid  var(--primary-gold);
  border-radius: 10px;
  width: 200px;
  height: 70px;
  overflow: hidden;
}
      
form button:hover{
  background-color: var(--primary-gold);
  color: var(--primary-green);
  box-shadow: 0 0 0px var();
  padding-bottom: 0px;
}

textarea{
  resize: none !important;
}