@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .for {
    height: 600px;
  }

  .for .cont{
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .conten {
    height: 250px;
    padding: 0;
  }

  .conten .inbut-f {
    background-color: var(--color-transparent-dark);
    width: 90%;
  }

  .contt{
    display: flex;
    justify-content: center;
  }

  .contt #message {
    height: 50%;
    width: 90%;
    height: 60%;
  }

  form{
    width: 100%;
    padding: 0 10px;
  }

  form button{
    width: 140px;
    height: 60px;
    margin: 0px 0 10px 0;
  }
} 

@media (max-width:426px) and (min-width:375px) {
  .for {
    height: 600px;
    
  }

  .for .cont{
    width: 374px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .conten {
    height: 250px;
    padding: 0;
  }

  .conten .inbut-f {
    background-color: var(--color-transparent-dark);
    width: 90%;

  }

  .contt{
    display: flex;
    justify-content: center;
  }

  .contt #message {
    height: 50%;
    width: 90%;
    height: 60%;
  }

  form{
    width: 100%;
    padding: 0 10px;
  }

  form button{
    width: 140px;
    height: 60px;
    margin: 0px 0 10px 0;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .for {
    height: 600px;
  }

  .for .cont{
    width: 350px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .conten {
    height: 250px;
    padding: 0;
  }

  .conten .inbut-f {
    background-color: var(--color-transparent-dark);
    width: 90%;
  }

  .contt{
    width: 350px !important;
    display: flex;
    justify-content: center;
  }

  .contt #message {
    height: 50%;
    width: 90%;
    height: 60%;
  }

  form{
    width: 100%;
    padding: 0 10px;
  }

  form button{
    width: 140px;
    height: 60px;
    margin: 0px 0 10px 0;
  }
}

@media (max-width:320px){} 