@media (max-width:2560px) and (min-width:1441px) {}

@media (max-width:1440px) and (min-width:1201px) {}

@media (max-width:1200px) and (min-width:1025px) {}

@media (max-width:1024px) and (min-width:993px) {}

@media (max-width:992px) and (min-width:769px) {}

@media (max-width:768px) and (min-width:577px) {}

@media (max-width:576px) and (min-width:426px) {
  .boxffes{
    width: 100%;
    height: 220px;
  }

  
  .boxffes .imgeees {
    border-radius: 20px;
    width: 92%;
  }
  

  .conteree{
    width: 366px;
    height: 250px;
    /* padding: 0 20px !important; */
  }

  .wides{
    text-align: center;
  }
} 

@media (max-width:426px) and (min-width:375px) {
  .boxffes{
    width: 99%;
    height: 220px;
  }

  
  .boxffes .imgeees {
    border-radius: 20px;
    width: 91%;
  }
  

  .conteree{
    width: 366px;
    height: 250px;
  }

  .wides{
    text-align: center;
  }
}

@media (max-width:375px) and (min-width:320px) {
  .boxffes{
    width: 98%;
    height: 220px;
  }

  
  .boxffes .imgeees {
    border-radius: 20px;
    width: 92%;
  }
  

  .conteree{
    width: 336px;
    height: 250px;
    /* padding: 0 20px !important; */
  }

  .wides{
    text-align: center;
  }
}

@media (max-width:320px){} 
